.search-container {
  grid-area: search;
  position: relative;
}

.search-container svg {
  height: 1.5rem;
  width: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .search-container svg {
    height: 1.25rem;
    width: 1.25rem;
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.search-container svg {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 5px;
}

@media (min-width: 768px) {
  .search-container svg {
    top: 7px;
  }
}

@media (max-width: 640px) {
  .search-container:not(.home) svg {
    top: 7px;
  }
}

.search-container input {
  width: 24px;
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  border-width: 1px;
  border-color: #0000;
  padding: .25rem 1.25rem .25rem 0;
}

.search-container input:focus {
  padding-left: 2rem;
}

@media (min-width: 768px) {
  .search-container input {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity));
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
    --tw-bg-opacity: .7;
    border-radius: 9999px;
    padding-left: 2rem;
  }
}

.search-container input:focus {
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  --tw-bg-opacity: .7;
  border-radius: 9999px;
}

.search-container input {
  opacity: 0;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.search-container input:focus {
  width: 10rem;
  opacity: 1;
}

@media (min-width: 375px) {
  .search-container input:focus {
    width: 12rem;
  }
}

@media (min-width: 768px) {
  .search-container input {
    width: 12rem;
    opacity: 1;
  }
}

.search-container input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px;
  outline: none;
}

.search-container.home input {
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
  --tw-border-opacity: .5;
  --tw-bg-opacity: .3;
}

.search-container.home input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(249 168 212 / var(--tw-placeholder-opacity));
}

.dark .search-container.home input {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
  --tw-bg-opacity: .4;
}

.search-container.home input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
  --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity));
  --tw-ring-opacity: .5;
}

.dark .search-container.home input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity));
}

.search .search-container svg {
  height: 1.25rem;
  width: 1.25rem;
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 7px;
}

@media (max-width: 640px) {
  .search .search-container:not(.home) svg {
    top: 9px;
  }
}

body .algolia-autocomplete .ds-dropdown-menu:before {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.loaded body .algolia-autocomplete .ds-dropdown-menu:before {
  transition: background .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, text-decoration-color .2s ease-in-out;
}

body .algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.loaded body .algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
  transition: background .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, text-decoration-color .2s ease-in-out;
}

body .algolia-autocomplete .algolia-docsearch-suggestion {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.loaded body .algolia-autocomplete .algolia-docsearch-suggestion {
  transition: background .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, text-decoration-color .2s ease-in-out;
}

body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--category-header {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
  font-weight: 700;
}

.loaded body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--category-header {
  transition: color .2s ease-in-out;
}

body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.loaded body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column {
  transition: color .2s ease-in-out;
}

body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--title, body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--text {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.loaded body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--title, .loaded body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--text {
  transition: color .2s ease-in-out;
}

body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--highlight {
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
  --tw-bg-opacity: .4;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.loaded body .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--highlight {
  transition: color .2s ease-in-out;
}

body .algolia-autocomplete .ds-dropdown-menu .ds-suggestion.ds-cursor .algolia-docsearch-suggestion.algolia-docsearch-suggestion .algolia-docsearch-suggestion--content {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

@media (max-width: 640px) {
  body .algolia-autocomplete .ds-dropdown-menu {
    max-width: unset;
    min-width: unset;
    left: 0;
    position: fixed !important;
    top: 60px !important;
  }

  body .algolia-autocomplete .ds-dropdown-menu > div {
    height: calc(var(--viewport-height)  - 60px);
    overflow: auto;
  }

  body .algolia-autocomplete .ds-dropdown-menu, body .algolia-autocomplete .ds-dropdown-menu .algolia-docsearch-suggestion--wrapper {
    width: 100vw !important;
  }

  body .algolia-autocomplete .ds-dropdown-menu:before, body .algolia-autocomplete .ds-dropdown-menu .algolia-docsearch-suggestion--subcategory-column {
    display: none;
  }

  body .algolia-autocomplete .ds-dropdown-menu .algolia-docsearch-suggestion--content {
    width: 100%;
  }

  body .algolia-autocomplete .ds-dropdown-menu > div, body .algolia-autocomplete .ds-dropdown-menu .algolia-docsearch-suggestion {
    padding-left: 0;
  }

  body .algolia-autocomplete .ds-dropdown-menu .algolia-docsearch-suggestion--category-header, body .algolia-autocomplete .ds-dropdown-menu .algolia-docsearch-suggestion--title {
    padding-left: .5rem;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 375px) {
  .container {
    max-width: 375px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.right-1 {
  right: .25rem;
}

.left-2 {
  left: .5rem;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.left-4 {
  left: 1rem;
}

.top-4 {
  top: 1rem;
}

.top-2 {
  top: .5rem;
}

.isolate {
  isolation: isolate;
}

.z-0 {
  z-index: 0;
}

.order-2 {
  order: 2;
}

.order-1 {
  order: 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.m-1 {
  margin: .25rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-40 {
  margin-top: 10rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.ml-0 {
  margin-left: 0;
}

.-ml-9 {
  margin-left: -2.25rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-3 {
  height: .75rem;
}

.h-20 {
  height: 5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-80 {
  height: 20rem;
}

.h-60 {
  height: 15rem;
}

.h-48 {
  height: 12rem;
}

.h-1 {
  height: .25rem;
}

.h-128 {
  height: 29rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-auto {
  width: auto;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-3 {
  width: .75rem;
}

.w-2 {
  width: .5rem;
}

.w-32 {
  width: 8rem;
}

.w-72 {
  width: 18rem;
}

.w-1 {
  width: .25rem;
}

.w-full-screen {
  width: calc(100vw - 20px);
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-full-screen {
  max-width: calc(100vw - 20px);
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-y-28 {
  --tw-translate-y: -7rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-6 {
  --tw-skew-y: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-auto {
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.appearance-none {
  appearance: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-8 {
  gap: 2rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-t-sm {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.border-opacity-10 {
  --tw-border-opacity: .1;
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-blue-200 {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-to: #bfdbfe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200 {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-to: #ddd6fe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-200 {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-to: #fbcfe800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-fuchsia-200 {
  --tw-gradient-from: #f5d0fe;
  --tw-gradient-to: #f5d0fe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-600 {
  --tw-gradient-from: #2563eb;
  --tw-gradient-to: #2563eb00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-100 {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-to: #ede9fe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-100 {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-to: #e0e7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-to: #3b82f600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500 {
  --tw-gradient-from: #6366f1;
  --tw-gradient-to: #6366f100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-100 {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-to: #d1fae500;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-100 {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-to: #dbeafe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50 {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-to: #f9fafb00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-500 {
  --tw-gradient-from: #f97316;
  --tw-gradient-to: #f9731600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow-50 {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-to: #fffbeb00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red-100 {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-to: #fee2e200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600 {
  --tw-gradient-from: #db2777;
  --tw-gradient-to: #db277700;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-100 {
  --tw-gradient-from: #ffedd5;
  --tw-gradient-to: #ffedd500;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-300 {
  --tw-gradient-from: #fdba74;
  --tw-gradient-to: #fdba7400;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-300 {
  --tw-gradient-from: #fda4af;
  --tw-gradient-to: #fda4af00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500 {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-to: #8b5cf600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-50 {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-to: #f5f3ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-400 {
  --tw-gradient-from: #34d399;
  --tw-gradient-to: #34d39900;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-blue-400 {
  --tw-gradient-to: #60a5fa00;
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to);
}

.via-indigo-600 {
  --tw-gradient-to: #4f46e500;
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to);
}

.via-indigo-500 {
  --tw-gradient-to: #6366f100;
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to);
}

.via-blue-500 {
  --tw-gradient-to: #3b82f600;
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to);
}

.via-yellow-400 {
  --tw-gradient-to: #fbbf2400;
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to);
}

.via-yellow-500 {
  --tw-gradient-to: #f59e0b00;
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to);
}

.via-orange-200 {
  --tw-gradient-to: #fed7aa00;
  --tw-gradient-stops: var(--tw-gradient-from), #fed7aa, var(--tw-gradient-to);
}

.via-pink-500 {
  --tw-gradient-to: #ec489900;
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to);
}

.to-purple-200 {
  --tw-gradient-to: #ddd6fe;
}

.to-pink-200 {
  --tw-gradient-to: #fbcfe8;
}

.to-orange-200 {
  --tw-gradient-to: #fed7aa;
}

.to-violet-200 {
  --tw-gradient-to: #ddd6fe;
}

.to-indigo-200 {
  --tw-gradient-to: #c7d2fe;
}

.to-blue-600 {
  --tw-gradient-to: #2563eb;
}

.to-blue-100 {
  --tw-gradient-to: #dbeafe;
}

.to-pink-100 {
  --tw-gradient-to: #fce7f3;
}

.to-pink-500 {
  --tw-gradient-to: #ec4899;
}

.to-purple-500 {
  --tw-gradient-to: #8b5cf6;
}

.to-green-500 {
  --tw-gradient-to: #10b981;
}

.to-green-100 {
  --tw-gradient-to: #d1fae5;
}

.to-white {
  --tw-gradient-to: #fff;
}

.to-orange-500 {
  --tw-gradient-to: #f97316;
}

.to-yellow-100 {
  --tw-gradient-to: #fef3c7;
}

.to-yellow-50 {
  --tw-gradient-to: #fffbeb;
}

.to-red-100 {
  --tw-gradient-to: #fee2e2;
}

.to-pink-600 {
  --tw-gradient-to: #db2777;
}

.to-rose-100 {
  --tw-gradient-to: #ffe4e6;
}

.to-rose-200 {
  --tw-gradient-to: #fecdd3;
}

.to-orange-300 {
  --tw-gradient-to: #fdba74;
}

.to-yellow-500 {
  --tw-gradient-to: #f59e0b;
}

.to-purple-100 {
  --tw-gradient-to: #ede9fe;
}

.to-red-500 {
  --tw-gradient-to: #ef4444;
}

.to-yellow-300 {
  --tw-gradient-to: #fcd34d;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-4 {
  padding: 1rem;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-text-top {
  vertical-align: text-top;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-tight {
  line-height: 1.25;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-100 {
  opacity: 1;
}

.opacity-70 {
  opacity: .7;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-100 {
  transition-delay: .1s;
}

.duration-700 {
  transition-duration: .7s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.px-safe {
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
}

.h-screen-safe {
  height: calc(100vh - (env(safe-area-inset-top)  + env(safe-area-inset-bottom) ) );
  height: -webkit-fill-available;
}

pre {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-radius: .375rem;
  padding: 1rem;
}

@media (prefers-color-scheme: dark) {
  pre {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.token.punctuation {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .token.property, .token.tag, .token.constant, .token.symbol, .token.deleted {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity));
  }
}

.token.boolean, .token.number {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string, .token.variable {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.token.atrule, .token.attr-value, .token.function, .token.class-name {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.token.keyword {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.token.regex, .token.important {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

[role="tab"] {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
  padding: .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

[role="tab"].focus-visible {
  z-index: 10;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity));
}

@media (prefers-color-scheme: dark) {
  [role="tab"] {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
  }
}

@media (min-width: 1280px) {
  [role="tab"] {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

[role="tab"][aria-selected="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  [role="tab"][aria-selected="true"] {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
}

[role="tab"][aria-selected="true"] + [role="tab"] {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

@media (prefers-color-scheme: dark) {
  [role="tab"][aria-selected="true"] + [role="tab"] {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
  }
}

[role="tab"]:first-child {
  border-style: none;
}

[role="radio"] {
  --tw-bg-opacity: 0;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  outline-offset: 2px;
  border-radius: .375rem;
  outline: 2px solid #0000;
  margin-bottom: .5rem;
  padding: .25rem 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[role="radio"].focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity));
  --tw-ring-opacity: .5;
}

@media (prefers-color-scheme: dark) {
  [role="radio"] {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity));
  }
}

@media (min-width: 1024px) {
  [role="radio"] {
    cursor: pointer;
  }

  [role="radio"]:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  @media (prefers-color-scheme: dark) {
    [role="radio"] {
      --tw-text-opacity: 1;
      color: rgb(253 164 175 / var(--tw-text-opacity));
    }

    [role="radio"]:hover {
      --tw-text-opacity: 1;
      color: rgb(243 244 246 / var(--tw-text-opacity));
    }
  }

  [role="radio"][aria-checked="true"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity));
  }

  @media (prefers-color-scheme: dark) {
    [role="radio"][aria-checked="true"] {
      background-color: rgb(251 146 60 / var(--tw-bg-opacity));
      --tw-bg-opacity: .75;
      --tw-text-opacity: 1;
      color: rgb(136 19 55 / var(--tw-text-opacity));
    }
  }

  [role="radio"][aria-checked="true"] p:last-child {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity));
  }

  @media (prefers-color-scheme: dark) {
    [role="radio"][aria-checked="true"] p:last-child {
      --tw-text-opacity: 1;
      color: rgb(136 19 55 / var(--tw-text-opacity));
    }
  }
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: .25;
}

.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:scale-105:focus {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.focus\:border-sky-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

.focus\:shadow-xl:focus {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-sky-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity));
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.active\:bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.disabled\:bg-gray-100:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.disabled\:opacity-30:disabled {
  opacity: .3;
}

@media (prefers-reduced-motion: no-preference) {
  .motion-safe\:translate-y-3 {
    --tw-translate-y: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .motion-safe\:-translate-y-3 {
    --tw-translate-y: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .motion-safe\:-translate-x-3 {
    --tw-translate-x: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .motion-safe\:translate-x-3 {
    --tw-translate-x: .75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }

  .dark\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
  }

  .dark\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity));
  }

  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }

  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }

  .dark\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity));
  }

  .dark\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-to: #1e40af00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-to: #5b21b600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-to: #9d174d00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-fuchsia-800 {
    --tw-gradient-from: #86198f;
    --tw-gradient-to: #86198f00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-to: #3b82f600;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-to: #4c1d9500;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-to: #312e8100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-to: #1d4ed800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-emerald-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-to: #10b98100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-to: #818cf800;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-to: #1e3a8a00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-to: #064e3b00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-to: #11182700;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-to: #f59e0b00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-to: #83184300;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-orange-800 {
    --tw-gradient-from: #9a3412;
    --tw-gradient-to: #9a341200;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-rose-800 {
    --tw-gradient-from: #9f1239;
    --tw-gradient-to: #9f123900;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:from-rose-700 {
    --tw-gradient-from: #be123c;
    --tw-gradient-to: #be123c00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .dark\:via-blue-300 {
    --tw-gradient-to: #93c5fd00;
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to);
  }

  .dark\:via-purple-800 {
    --tw-gradient-to: #5b21b600;
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to);
  }

  .dark\:via-green-500 {
    --tw-gradient-to: #10b98100;
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to);
  }

  .dark\:via-blue-400 {
    --tw-gradient-to: #60a5fa00;
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to);
  }

  .dark\:via-yellow-200 {
    --tw-gradient-to: #fde68a00;
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to);
  }

  .dark\:via-yellow-400 {
    --tw-gradient-to: #fbbf2400;
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to);
  }

  .dark\:via-pink-900 {
    --tw-gradient-to: #83184300;
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to);
  }

  .dark\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .dark\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .dark\:to-orange-800 {
    --tw-gradient-to: #9a3412;
  }

  .dark\:to-violet-800 {
    --tw-gradient-to: #5b21b6;
  }

  .dark\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .dark\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .dark\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .dark\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .dark\:to-lime-500 {
    --tw-gradient-to: #84cc16;
  }

  .dark\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .dark\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .dark\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .dark\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .dark\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .dark\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .dark\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .dark\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
  }

  .dark\:text-rose-500 {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity));
  }

  .dark\:text-fuchsia-500 {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity));
  }

  .dark\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity));
  }

  .dark\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity));
  }

  .dark\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
  }

  .dark\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity));
  }

  .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }

  .dark\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .dark\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity));
  }

  .dark\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity));
  }

  .dark\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity));
  }

  .dark\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity));
  }

  .dark\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }

  .dark\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity));
  }

  .dark\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity));
  }

  .dark\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity));
  }

  .dark\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity));
  }

  .dark\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity));
  }

  .dark\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity));
  }

  .dark\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity));
  }

  .dark\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity));
  }

  .dark\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity));
  }

  .dark\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity));
  }

  .dark\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity));
  }

  .dark\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity));
  }

  .dark\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity));
  }

  .dark\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
  }

  .dark\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity));
  }

  .dark\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity));
  }

  .dark\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity));
  }

  .dark\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity));
  }

  .dark\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
  }

  .dark\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity));
  }

  .dark\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity));
  }

  .dark\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity));
  }

  .dark\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity));
  }

  .dark\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity));
  }

  .dark\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity));
  }

  .dark\:focus\:border-sky-600:focus {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity));
  }

  .dark\:focus\:ring-sky-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity));
  }

  .dark\:active\:bg-gray-700:active, .dark\:disabled\:bg-gray-700:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:order-1 {
    order: 1;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-36 {
    width: 9rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:-mt-9 {
    margin-top: -2.25rem;
  }

  .md\:mt-9 {
    margin-top: 2.25rem;
  }

  .md\:h-80 {
    height: 20rem;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:from-violet-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-to: #ddd6fe00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-to: #c7d2fe00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .md\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .md\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  @media (prefers-color-scheme: dark) {
    .md\:dark\:from-violet-800 {
      --tw-gradient-from: #5b21b6;
      --tw-gradient-to: #5b21b600;
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }

    .md\:dark\:from-indigo-800 {
      --tw-gradient-from: #3730a3;
      --tw-gradient-to: #3730a300;
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }

    .md\:dark\:to-indigo-800 {
      --tw-gradient-to: #3730a3;
    }

    .md\:dark\:to-blue-800 {
      --tw-gradient-to: #1e40af;
    }
  }
}

@media (min-width: 1024px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mr-12 {
    margin-right: 3rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:h-36 {
    height: 9rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-80 {
    height: 20rem;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }
}

@media (min-width: 1280px) {
  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

/*# sourceMappingURL=index.3d2f6e6a.css.map */
